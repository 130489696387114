import { useState } from "react";
import Messenger from "./components/Messenger";
import ImagePopup from "./components/ImagePopup";
import LoginScreen from "./components/LoginComponent";

const App = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [link, setLink] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <>
      {isLoggedIn ? (
        <div className="bg-img object-cover">
          <ImagePopup
            isOpen={isPopupOpen}
            link={link}
            setLink={setLink}
            setIsPopupOpen={setIsPopupOpen}
          />
          <Messenger showPopup={setIsPopupOpen} setLink={setLink} link={link} />
        </div>
      ) : (
        <LoginScreen onLogin={handleLogin} />
      )}
    </>
  );
};

export default App;
