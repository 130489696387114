import "../css/style.css";
import Message from "./Message";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ImagePopup from "./ImagePopup";

function Messenger({ showPopup, setLink, link }) {
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [textareaHeight, setTextareaHeight] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const domain = process.env.REACT_APP_DOMAIN;

  useEffect(() => {
    setTimeout(() => {
      const latestMessage = document.querySelector(".chatBoxTop > :last-child");
      if (latestMessage) {
        latestMessage.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  });

  function showIfImage(newMessage) {
    var links = newMessage.match(/\b(http|https)(:\/\/)(\S+)\b/gi);
    if (links != null) {
      const link = links[0];
      if (
        link.endsWith(".gltf") ||
        link.endsWith(".glb") ||
        link.endsWith(".png") ||
        link.endsWith(".jpg") ||
        link.endsWith(".jpeg")
      ) {
        setLink(link);
        setIsPopupOpen(true);
      }
    }
  }

  const adjustTextareaHeight = (event) => {
    const element = event.target;

    if (screenWidth < 786) {
      element.style.height = "40px";
      element.style.height = `${Math.max(element.scrollHeight, 40)}px`;
    } else {
      element.style.height = "63px";
      element.style.height = `${Math.max(element.scrollHeight, 63)}px`;
    }

    setTextareaHeight(`${element.style.height}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const id = uuidv4();
    const reply = { id: uuidv4(), text: "", own: false };

    setMessages([...messages, { id, text: newMessage, own: true }, reply]);

    if (screenWidth < 786) {
      setTextareaHeight("40px");
    } else {
      setTextareaHeight("63px");
    }

    try {
      setNewMessage("");

      const f = fetch(`${domain}/answer`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          question: newMessage,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      f.then((response) => response.json()).then((data) => {
        setMessages((prevMessages) =>
          prevMessages.map((message) => {
            if (message.id === reply.id) {
              return { ...message, text: data.answer };
            }
            return message;
          })
        );

        showIfImage(data.answer);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(event);
    }
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <div className="header w-full justify-between items-center">
          <img
            className="maxxLogo w-20 md:w-28 lg:w-32 xl:w-32 2xl:w-1/12"
            src={require("../images/maxxLogo.jpg")}
            alt="logo-img"
          />
          <h1 className="center text-3xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl">
            Ask Maxx
          </h1>
        </div>
        <div className="flex-1 messenger">
          <div className="chatMenu ">
            <div className="chatMenuWrapper"></div>
          </div>

          <div className="chatBox">
            <div className="chatBoxWrapper">
              <div className="chatBoxTop">
                {messages.map((message) => (
                  <Message
                    text={message.text}
                    own={message.own}
                    key={message.id}
                    showIfImage={showIfImage}
                  />
                ))}
              </div>
              <div className="chatBoxBottom"></div>
            </div>
          </div>

          <div className="chatOnline ">
            <div className="chatOnlineWrapper"></div>
          </div>
        </div>

        <form className="formStyle">
          <div className="chatBox1">
            <textarea
              type="text"
              className="messageInput"
              placeholder="Enter your query"
              onChange={(e) => {
                setNewMessage(e.target.value);
                adjustTextareaHeight(e);
              }}
              onKeyDown={handleKeyDown}
              maxLength={200}
              value={newMessage}
              style={{
                height: textareaHeight
                  ? textareaHeight
                  : screenWidth < 786
                  ? "40px"
                  : "63px",
                maxHeight: "150px",
                overflowY: "auto",
              }}
            ></textarea>
            <button
              id="chat-icon"
              type="submit"
              onClick={handleSubmit}
              className="ml-2"
            >
              <i className="fa-solid fa-circle-arrow-up fa-xl"></i>
            </button>
          </div>
        </form>
      </div>
      <ImagePopup
        isOpen={isPopupOpen}
        link={link} // Pass the `link` prop here
        setLink={setLink}
        setIsPopupOpen={setIsPopupOpen}
      />
    </>
  );
}

export default Messenger;
