import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import './ModelViewer.css'; // Import the CSS file

function ModelViewer({ modelUrl }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!modelUrl) return; // Early return if no modelUrl is provided

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);

    camera.position.set(1, 2, 3);
    new OrbitControls(camera, renderer.domElement);

    const loader = new GLTFLoader();

    // Logging modelUrl to ensure it's correct
    console.log("Loading model from URL:", modelUrl);

    loader.load(
      modelUrl,
      (gltf) => {
        const model = gltf.scene;
        const box = new THREE.Box3().setFromObject(model);
        const size = box.getSize(new THREE.Vector3()).length();
        model.scale.setScalar(1 / size);
        scene.add(model);
      },
      undefined,
      (error) => console.error("Error loading model:", error)
    );
    const light = new THREE.AmbientLight(0xffffff, 3); // 2 is the intensity
    scene.add(light);
    const lightPositions = [
      { x: 1, y: 1, z: 1 },
      { x: -1, y: 1, z: 1 },
      { x: 1, y: 1, z: -1 },
      { x: -1, y: 1, z: -1 },
      { x: 1, y: -1, z: 1 },
      { x: -1, y: -1, z: 1 },
      { x: 1, y: -1, z: -1 },
      { x: -1, y: -1, z: -1 },
    ];
    lightPositions.forEach((position) => {
      const light = new THREE.SpotLight(0xffffff, 0.5);
      light.castShadow = true;
      light.position.set(position.x, position.y, position.z);
      scene.add(light);
    });

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
    animate();

    return () => {
      renderer.dispose();
    };
  }, [modelUrl]);

  return (
    <div ref={containerRef} className="model-viewer-container"></div>
  );
}

export default ModelViewer;
