import ModelViewer from "./Model 3D/ModelViewer";

const ImagePopup = ({ isOpen, link, setLink, setIsPopupOpen }) => {
  const togglePopup = () => {
    setLink("");
    setIsPopupOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex justify-center items-center"
          onClick={togglePopup}
        >
          <div
            className="relative bg-black rounded-lg overflow-hidden w-4/5 md:w-2/3 lg:w-1/2 h-70vh flex justify-center items-center"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Popup header */}
            <div className="absolute top-2 right-2 z-10">
              <button
                onClick={togglePopup}
                className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-200"
              >
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Popup body */}
            <div className="w-50% h-full flex justify-center items-center p-4 ">
              {link.endsWith(".gltf") || link.endsWith(".glb") ? (
                <ModelViewer
                  modelUrl={link}
                  className="max-w-full max-h-full"
                />
              ) : (
                <img
                  src={link}
                  alt="Popup"
                  className="max-w-full max-h-full object-contain"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImagePopup;
